const INITIAL_COLORS = [
	{
		light: "#5CFDFD",
		dark: "#00B6B6"
	},
	{
		light: "#FD5C8C",
		dark: "#FF004C"
	},
	{
		light: "#F3FF6F",
		dark: "#DBEF00"
	},
	{
		light: "#FF29C4",
		dark: "#640066"
	},
	{
		light: "#46FF6E",
		dark: "#1DD745"
	}
];

export const BINGO_COLORS = [...Array(2)].flatMap(() => INITIAL_COLORS);
